import React, { useEffect } from 'react';
import './Legal.css';

function Legal() {
    useEffect(() => {
        document.getElementById('legal')?.classList.add('active');
    });

    return(
        <div className='legal-content'>
            <h2>LEGAL WARNING</h2>
            <h3>Identification of the information society service provider</h3>
            In order to comply with article 10 of Law 34/2002 on Information Society Services and Electronic Commerce,
            we inform the User of our data:
            <ul>Website: www.piramide.tv</ul>
            <ul>Entity: SCIA DEVELOPERS, SL</ul>
            <ul>Commercial Name: PIRAMIDE.TV</ul>
            <ul>Address: CARRER DIPUTACIO, Nº338, ENTRESUELO 3 - 08009 - BARCELONA - BARCELONA</ul>
            <ul>NIF: B05418447</ul>
            <ul>Phone:</ul>
            <ul>Email: kristian@mcr-agency.com</ul>
            <h3>Object</h3>
            Website, makes this document available to Users with which it intends to comply with the obligations established
            in Law 34/2002, on Information Society Services and Electronic Commerce (LSSI-CE), as well as informing all users
            of the website regarding the conditions of use of the website.
            <br/>
            Any person who accesses this website assumes the role of user, committing to strict observance and compliance
            with the provisions set forth herein, as well as any other legal provision that may be applicable.
            <br/>
            The provider reserves the right to modify any type of information that may appear on the website,
            without any obligation to notify or inform users of said obligations, with publication on the provider's
            website being understood as sufficient.
            <h3>Responsibility</h3>
            The provider is exempt from any type of responsibility derived from the information published on its website,
            provided that this information has been manipulated or introduced by a third party unrelated to it.
            <br/>
            On this website there may be links to content from third party websites. Since the provider cannot always control
            the content introduced by third parties on its websites, it does not assume any type of responsibility with respect
            to said content. In any case, the provider states that it will proceed to the immediate withdrawal of any content
            that could contravene national or international legislation, morality or public order, proceeding to the immediate
            withdrawal of the redirection to said website, informing the competent authorities the content in question.
            <br/>
            The provider is not responsible for the information and content stored, by way of example but not limited to,
            in forums, chats, blog generators, comments, social networks or any other means that allows third parties to
            publish content independently on the website of the provider.
            <br/>
            On this website we have adopted the relevant security measures to guarantee the confidentiality, integrity and
            availability of the information. We use the HTTPS (SSL) protocol to establish a secure communication channel
            between the server and the user to send your personal data. Providing this service through a secure server
            means guaranteeing the confidentiality of the information that is exchanged between the user's computer and
            said server, since an encrypted connection is established between the two, which makes it impossible for
            third parties to access the information sent. This website has been reviewed and tested to function correctly.
            In principle, correct operation can be guaranteed 365 days a year, 24 hours a day. However, the provider does
            not rule out the possibility that there may be certain programming errors, or that force majeure,
            natural disasters, strikes, or similar circumstances may occur that make access to the website impossible.
            <h3>Intellectual and industrial property</h3>
            This website, including, but not limited to, its programming, editing, compilation and other elements
            necessary for its operation, the designs, logos, text and graphics are the property of the provider or,
            where applicable, have a license or express authorization from them. of the authors. All contents of the
            website are duly protected by intellectual and industrial property regulations, and, where appropriate,
            registered in the corresponding public registries.
            <br/>
            Regardless of the purpose for which they were intended, the total or partial reproduction, use, exploitation,
            distribution and marketing of the contents of this website, requires in all cases prior written authorization
            from the provider. Any use not previously authorized by the provider will be considered a serious breach of the
            author's intellectual or industrial property rights.
            <br/>
            The designs, logos, text and/or graphics other than the provider and that may appear on the website belong
            to their respective owners, who are themselves responsible for any possible controversy that may arise regarding them.
            <br/>
            The provider recognizes in favor of its owners the corresponding industrial and intellectual property rights,
            its mere mention or appearance on the website not implying the existence of any rights or responsibility of the
            provider over them, nor endorsement, sponsorship or recommendation on the part of the provider. of the same.
            To make any type of observation regarding possible breaches of intellectual or industrial property rights,
            as well as any of the contents of the website, you can do so through the email or telephone number indicated
            in the provider identification section of this notice legal.
            <h3>Veracity of information and minors</h3>
            All information provided by the User must be true. For these purposes, the User guarantees the authenticity
            of the data communicated through the forms for subscription to the Services. It will be the User's responsibility
            to keep all the information provided to the provider permanently updated so that it responds, at all times,
            to their real situation. In any case, the User will be solely responsible for any false or inaccurate statements
            made and for any damage caused to the provider or third parties. When using the services of this website,
            minors must always obtain prior consent from parents, guardians or legal representatives, who are ultimately
            responsible for all acts carried out by minors in their care. The responsibility for determining the specific
            content to which minors access corresponds to them, which is why if they access inappropriate content on
            the Internet, mechanisms will have to be established on their computers, in particular computer programs,
            filters and blocks, which They allow you to limit the available content and, although they are not infallible,
            they are especially useful for controlling and restricting the materials that minors can access.
            <h3>Conditions of access and use</h3>
            Access to this website is free of charge for the User, who undertakes to use this website in accordance
            with the law and this legal notice, as well as morality and good customs. To this end, the User will refrain
            from using the page for illegal or prohibited purposes, harmful to the rights and interests of third parties,
            or that in any way may damage, disable, overload, deteriorate or prevent the normal use of computer equipment
            or documents. files and all types of content stored on any computer equipment of the provider. In particular,
            and as an indication but not exhaustive, the User undertakes not to transmit, disseminate or make available to
            third parties, information, data, content, messages, graphics, drawings, sound or image files, photographs,
            recordings, software and In general, any kind of material that:
            <ul>
                (a) is contrary to, disregards or violates fundamental rights and public freedoms recognized constitutionally,
                in international treaties and other current regulations;
            </ul>
            <ul>
                (b) induces, incites or promotes criminal, denigrating, defamatory, violent or, in general, actions contrary
                to the law, morality and public order;
            </ul>
            <ul>
                (c) induces, incites or promotes discriminatory actions, attitudes or thoughts based on sex,
                race, religion, beliefs, age or condition;
            </ul>
            <ul>
                (d) is contrary to the right to honor, personal or family privacy or a person's own image;
            </ul>
            <ul>
                (e) in any way damages the credibility of the provider or third parties;
            </ul>
            <ul>
                (f) constitutes illegal, misleading or unfair advertising.
            </ul>
            <h3>Applicable Law and Jurisdiction</h3>
            For the resolution of all disputes or issues related to this website or the activities
            carried out on it, Spanish legislation will apply, to which the parties expressly submit,
            being competent for the resolution of all conflicts arising or related to its use,
            the Courts and Tribunals of the domicile of the person responsible for this website.
        </div>
    )
}

export default Legal;
