import React from 'react';
import './OlimpoBannerMobile.css'
import { olimpoBanner } from '../OlimpoHostingBanner.tsx';

function OlimpoBannerMobile() {
    return(
        <a className='olimpo-hosting-brand-mobile' href='https://clients.olimpohost.com/link.php?id=2'>
            <img alt='Olimpo Hosting' src={olimpoBanner}/>
        </a>
    );
}

export default OlimpoBannerMobile;
