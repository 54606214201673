import React, { useEffect } from 'react';
import './Cookies.css';

function Cookies() {
    useEffect(() => {
        document.getElementById('cookies')?.classList.add('active');
    });

    return(
        <div className='cookies-content'>
            <h2>COOKIES POLICY</h2>
            This Cookies Policy is an integral part of the legal texts of https://piramide.tv/ (hereinafter, the “Website”).
            Access and navigation on the Website, or the use of its services, imply acceptance of its legal texts.
            In order to facilitate your navigation through the Website, the company SCIA DEVOLOPERS SL (hereinafter "the provider"),
            a commercial company with registered office at C/ Diputacio 338 Entresuelo 3, 08009, Barcelona with NIF B05418447
            communicates that it uses Cookies or other files with similar functionality such as Pixels,
            Tags or online identifiers (hereinafter, the “Cookies”).
            <br/>
            In any case, we inform you that the provider is responsible for the Cookies and the processing of the data obtained
            through its own and third-party Cookies, deciding on the purpose, content and use of the processing of
            the information collected.
            <h3>Definition and functions of cookies</h3>
            Cookies are small text files that are stored on the user's device when they visit websites.
            They can serve, for example, to recognize you as a user, obtain information about your browsing habits
            or personalize the way in which content is displayed.
            The specific uses we make of these technologies are described below.
            <h3>Information about the type of cookies we use</h3>
            The Website uses the following Cookies:
            <h4>Technical cookies:</h4>
            These are those that allow the user to navigate through the website and use
            the different options or services that exist therein. They are essential for the operation of the web portal,
            allowing access to the different functionalities it has.
            <br/>
            Cookies are necessary for the website to function and therefore cannot be deactivated.
            <h4>Preference cookies:</h4>
            These are those that allow the user to remember information so that they
            can access it. to the service with certain characteristics that may differentiate your experience from that of
            other users, such as, for example, the language, the number of results to be displayed, etc. These cookies will
            be disabled by default.
            <h4>Analytical cookies:</h4>
            These are those that allow the number of users to be quantified and thus carry out
            the measurement and statistical analysis of the use made by users of the service offered. To do this,
            your navigation on our website is analyzed in order to improve the offer of products or services that we offer.
            These cookies will be disabled by default.
            <h4>Advertising cookies:</h4>
            These are those that allow your internet browsing habits to be analyzed so that
            advertising related to your browsing profile can be shown to you. These cookies will be disabled by default.
            <br/>
            Depending on their ownership, cookies can be divided into “own cookies” or “third party cookies”.
            The “own cookies” are created by SCIA DEVOLOPERS SL and the “third party cookies”
            are those created by third parties other than SCIA DEVOLOPERS SL , who have their
            own policies, terms and conditions, on which SCIA DEVOLOPERS SL has no control.
            In this sense, you can see the third parties that collect information about you (including Youbora, Oracle or
            Facebook), the *pology of cookies used by said third parties, their purpose and expiration by accessing the
            configuration panel located in section #4. Configuration of cookies used on the website” of this cookie policy.
            <h3>Configuration of the cookies used on the website</h3>
            You can configure cookies that are not strictly necessary for navigation through the configuration panel.
            Please note that if you reject the use of cookies, some of the Web services may be affected.
            <h3>What to do to avoid the installation of cookies?</h3>
            The browser can be configured to automatically reject cookies. The necessary information to carry out said
            configuration appears in these links:
            <li>Firefox</li>
            <li>Google Chrome</li>
            <li>Internet Explorer</li>
            <li>Safari</li>
            <h3>International transfers</h3>
            You can find out about transfers to third countries that, where applicable, are carried out by third parties,
            identified in the configuration panel by accessing their corresponding cookie policies.
            <h3>Cookie settings on other platforms</h3>
            As with computer browsers, mobile device browsers allow you to make changes to privacy options or
            settings to deactivate or delete cookies.
            <br/>
            If you wish to modify the privacy options, follow the instructions specified by the developer of
            your mobile device browser.
            <br/>
            Below, you can find some examples of links that will guide you to modify the privacy options on your mobile device:
            <li>Safari for IOS</li>
            <li>Chrome for Android</li>
            <br/> 
            <div className='publication-date'>Publication date 11/03/2023</div>
            <span className='copyright'>© 2023</span><span>SCIA DEVOLOPERS SL</span>
        </div>
    )
}

export default Cookies;
